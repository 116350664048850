import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { WindowSizeProvider } from "./hooks/windowSize/windowSizeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WindowSizeProvider>
      <App />
    </WindowSizeProvider>
  </React.StrictMode>
);
