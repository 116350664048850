// components/DownloadButton.js
import React from "react";
import Button from "@mui/material/Button";
import resume from "../../assets/MattEvansResume.png";

const DownloadButton = () => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = resume;
    link.download = "Matt_Evans_Resume.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      style={{ height: "30px", width: "100px", marginBottom: "10px" }}
      variant="contained"
      color="primary"
      onClick={handleDownload}
    >
      Resume
    </Button>
  );
};

export default DownloadButton;
