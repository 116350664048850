import React, { createContext, useContext } from "react";
import useWindowSize from "./windowSize";

const WindowSizeContext = createContext();

export const WindowSizeProvider = ({ children }) => {
  const windowSize = useWindowSize();

  return (
    <WindowSizeContext.Provider value={windowSize}>
      {children}
    </WindowSizeContext.Provider>
  );
};

export const useWindowSizeContext = () => {
  return useContext(WindowSizeContext);
};
