import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { useWindowSizeContext } from "../hooks/windowSize/windowSizeContext";

function Projects() {
  const { width } = useWindowSizeContext();
  return (
    <Box
      id="projects"
      style={{
        padding: "20px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: width < 1000 ? "-130px" : "0px",
      }}
    >
      <Box style={{ maxWidth: "800px", width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h4" style={{ marginBottom: "20px" }}>
            Projects
          </Typography>
        </div>
        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Resource Site
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              An internal Resource Site used to centralize and maintain
              company-wide Standard Operating Procedures and facilitate
              organizational communication.
            </Typography>
            <Typography variant="body1" component="p">
              This web-based platform was built using React, TypeScript,
              Express, and MongoDB and currently serves approximately 200
              employees. Key features include a forum-styled homepage, a contact
              page, and a resources page.
            </Typography>
          </CardContent>
        </Card>

        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Material Management
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              A web-based Material Management Application designed to enable
              warehouse managers to effectively track and manage inventory
              levels.
            </Typography>
            <Typography variant="body1" component="p">
              Built with React, TypeScript, Express, and Prisma, this
              application streamlines the inventory management process through a
              suite of features including a Material Tracker, Label
              Maker/Printer, Barcode Scanner, and the ability to create and
              manage Purchase Orders/Job Requisitions.
            </Typography>
          </CardContent>
        </Card>
        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h5" component="div">
              MUI Component Library Extension
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              A component library built as an extension of the MUI component
              library that facilitates the quick deployment of internal
              applications.
            </Typography>
            <Typography variant="body1" component="p">
              It focuses on customized components that fit the needs of the
              organization and holds internal branding as well. This extension
              helps streamline development processes by providing pre-built,
              reusable components tailored to the organization's requirements.
            </Typography>
          </CardContent>
        </Card>

        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Codebase Documentation Tool
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              A tool to document our code base, built with Docusaurus.
            </Typography>
            <Typography variant="body1" component="p">
              This tool helps maintain comprehensive and accessible
              documentation for the development team, ensuring that the codebase
              is well-documented and easy to navigate. This site is published
              through GitHub Pages.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default Projects;
