import React, { useState, useMemo } from "react";
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Divider,
} from "@mui/material";
import "./styles/app.css";

// Import your components
import Navbar from "./components/Nav/NavBar";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Projects from "./components/Projects";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#6271a3",
    },
    secondary: {
      main: "#ccb8b4",
    },
    background: {
      default: "#d5dce5",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#312f3d",
    },
    divider: "#9b8480",
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#67254e",
    },
    secondary: {
      main: "#67254e",
    },
    background: {
      default: "#0b192f",
      paper: "#35356e",
    },
    text: {
      primary: "#ccd6f6",
      secondary: "#8892af",
    },
    divider: "#995764",
  },
});

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const theme = useMemo(() => (darkMode ? darkTheme : lightTheme), [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <Divider />

      <div>
        <div id="home">
          <Home />
        </div>
        <div id="about">
          <About />
        </div>
        <div id="projects">
          <Projects />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
