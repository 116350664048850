import React from "react";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import myImage from "../assets/matt.jpeg";
import { useWindowSizeContext } from "../hooks/windowSize/windowSizeContext";

function Home() {
  const theme = useTheme();
  const { width, height } = useWindowSizeContext();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <Box
      style={{
        padding: "0px 10px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: width < 1000 ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom:
          width < 1000 && width > 500
            ? "-20px"
            : width < 500
            ? "70px"
            : "-75px",
        marginTop: width < 1000 && width > 500 ? "-10px" : "0px",
      }}
    >
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: width < 1000 ? "50px" : "0px",
        }}
      >
        {width > 1200 ? (
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <Typography variant="h1" style={{ marginRight: "10px" }}>
              Hello!
            </Typography>
            <Typography
              variant="h1"
              style={{ color: isDarkMode ? "#67254e" : "#9b8480" }}
            >
              I'm Matt
            </Typography>
          </div>
        ) : width < 1200 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
              textAlign: "center",
              marginTop: width < 500 ? "60px" : "0px",
            }}
          >
            <Typography
              variant="h1"
              style={{ fontSize: width < 500 ? "3em" : "5em" }}
            >
              Hello!
            </Typography>
            <Typography
              variant="h1"
              style={{
                color: isDarkMode ? "#67254e" : "#9b8480",
                fontSize: width < 500 ? "3em" : "5em",
              }}
            >
              I'm Matt
            </Typography>
          </div>
        ) : null}

        <Typography
          variant="h5"
          style={{
            textAlign: "center",
            fontSize: width < 500 ? "1.75em" : "2.2em",
          }}
        >
          I'm a passionate full-stack developer dedicated to crafting intuitive
          and efficient web applications that ease the lives of users.
        </Typography>
      </div>
      <div
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar
          src={myImage}
          style={{
            height:
              height < 700
                ? "250px"
                : height < 800
                ? "300px"
                : height < 1000
                ? "350px"
                : "400px",
            width:
              height < 700
                ? "250px"
                : height < 800
                ? "300px"
                : height < 1000
                ? "350px"
                : "400px",
          }}
        />
      </div>
    </Box>
  );
}

export default Home;
