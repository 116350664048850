import React from "react";
import { Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import useSectionObserver from "../../hooks/useSectionObserver";
import { useWindowSizeContext } from "../../hooks/windowSize/windowSizeContext";

const Navbar = ({ darkMode, toggleDarkMode }) => {
  const theme = useTheme();
  const sectionIds = ["home", "about", "projects", "contact"];
  const activeSection = useSectionObserver(sectionIds);
  const isDarkMode = theme.palette.mode === "dark";
  const { width } = useWindowSizeContext();

  const getButtonStyle = (section) => ({
    textDecoration: activeSection === section ? "underline" : "none",
  });

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        width: "100%",
        zIndex: 1000,
        backgroundColor: isDarkMode ? "#67254e" : "#9b8480",
        padding: "10px",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
      }}
    >
      <div>
        <Button
          sx={{ fontSize: width < 900 ? ".75" : "1rem" }}
          color="inherit"
          href="#home"
          style={getButtonStyle("home")}
        >
          Home
        </Button>
        <Button
          sx={{ fontSize: width < 900 ? ".75" : "1rem" }}
          color="inherit"
          href="#about"
          style={getButtonStyle("about")}
        >
          About
        </Button>
        <Button
          sx={{ fontSize: width < 900 ? ".75" : "1rem" }}
          color="inherit"
          href="#projects"
          style={getButtonStyle("projects")}
        >
          Projects
        </Button>
        <Button
          sx={{ fontSize: width < 900 ? ".75" : "1rem" }}
          color="inherit"
          href="#contact"
          style={getButtonStyle("contact")}
        >
          Contact
        </Button>
        <Tooltip title="Toggle dark mode" placement="bottom">
          <IconButton onClick={toggleDarkMode} color="inherit">
            {darkMode ? (
              <Brightness7 sx={{ fontSize: width < 900 ? ".75" : "1rem" }} />
            ) : (
              <Brightness4 sx={{ fontSize: width < 900 ? ".75" : "1rem" }} />
            )}
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default Navbar;
