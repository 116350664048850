import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useWindowSizeContext } from "../hooks/windowSize/windowSizeContext";
import DownloadButton from "./helpers/DownloadButton";

function About() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const { width, height } = useWindowSizeContext();

  return (
    <Box
      style={{
        padding: "20px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: width < 1000 ? "-10px" : "0px",
        marginTop: height < 800 ? "50px" : "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" style={{ marginBottom: "5px" }}>
          About Me
        </Typography>
        <DownloadButton />
      </div>
      <Typography
        variant="h5"
        style={{ marginBottom: "20px", textAlign: "center", maxWidth: "800px" }}
      >
        With a strong foundation in both front-end and back-end development, I
        specialize in building dynamic and responsive web applications. My
        expertise lies in leveraging modern technologies to create seamless user
        experiences and robust functionalities.
      </Typography>
      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        <strong>Profile</strong>
      </Typography>
      <Typography
        sx={{ color: "text.secondary" }}
        paragraph
        style={{ maxWidth: "800px", textAlign: "justify" }}
      >
        I have extensive experience in full-stack web development, particularly
        with React, TypeScript, and modern back-end technologies . My technical
        skills, combined with my ability to enhance organizational processes and
        provide impactful technology training to new hires, make me a valuable
        asset to any team.
      </Typography>
      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        <strong>Skills</strong>
      </Typography>
      <Typography
        sx={{ color: "text.secondary" }}
        paragraph
        style={{ maxWidth: "800px", textAlign: "justify" }}
      >
        <strong style={{ color: isDarkMode ? "#ccd6f6" : "black" }}>
          Soft Skills:
        </strong>{" "}
        Communication, Leadership, Teamwork, Coaching, Empathy.
        <br />
        <strong style={{ color: isDarkMode ? "#ccd6f6" : "black" }}>
          Technical Skills:
        </strong>{" "}
        JavaScript, TypeScript, ReactJS, HTML, CSS, Git, MongoDB, NodeJs and
        Express.
      </Typography>

      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        <strong>Work Experience</strong>
      </Typography>
      <Typography
        sx={{ color: "text.secondary" }}
        paragraph
        style={{ maxWidth: "800px", textAlign: "justify" }}
      >
        <strong style={{ color: isDarkMode ? "#ccd6f6" : "black" }}>
          CF Evans Construction, Technology Solutions Engineer (10/22 –
          Present):
        </strong>{" "}
        Collaborated closely with the Technology Solutions Manager in developing
        and deploying internal web applications using JavaScript, React,
        TypeScript, MongoDB, Prisma, Node.js, and Express.js. Led technology
        onboarding for new hires and directed the launch of an internal Resource
        Site, conducting on-site demonstrations and support at various South
        Carolina job locations.
      </Typography>
      <Typography
        sx={{ color: "text.secondary" }}
        paragraph
        style={{ maxWidth: "800px", textAlign: "justify" }}
      >
        <strong style={{ color: isDarkMode ? "#ccd6f6" : "black" }}>
          SemperFi Design, Web Developer (08/22 – 10/22):
        </strong>{" "}
        Converted design assets into component-based HTML, CSS, and JavaScript.
        Provided front-end website development using WordPress.
      </Typography>
      <Typography
        sx={{ color: "text.secondary" }}
        paragraph
        style={{ maxWidth: "800px", textAlign: "justify" }}
      >
        <strong style={{ color: isDarkMode ? "#ccd6f6" : "black" }}>
          Red Lion Controls, Production Supervisor (01/19 – 1/22):
        </strong>{" "}
        Standardized production procedures, job roles, and quality assurance
        guidelines. Created and streamlined production schedules and
        collaborated with production employees to communicate objectives and
        goals.
      </Typography>
      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        <strong>Education</strong>
      </Typography>
      <Typography
        sx={{ color: "text.secondary" }}
        paragraph
        style={{ maxWidth: "800px", textAlign: "justify" }}
      >
        <strong style={{ color: isDarkMode ? "#ccd6f6" : "black" }}>
          Associate of Science in Computer Science, Bottega University (2023):
        </strong>{" "}
        Learned the basics of web and API development using tools such as HTML,
        CSS, Python/Flask, JavaScript, React, Git, and SCSS.
      </Typography>
      <Typography
        sx={{ color: "text.secondary" }}
        paragraph
        style={{ maxWidth: "800px", textAlign: "justify" }}
      >
        <strong style={{ color: isDarkMode ? "#ccd6f6" : "black" }}>
          Full Stack Certification, Bottega University (2022):
        </strong>{" "}
        Comprehensive training in full-stack development with a focus on both
        front-end and back-end technologies.
      </Typography>
    </Box>
  );
}

export default About;
