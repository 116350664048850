import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  IconButton,
  Snackbar,
  useTheme,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import { useWindowSizeContext } from "../hooks/windowSize/windowSizeContext";

function Contact() {
  const theme = useTheme();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const isDarkMode = theme.palette.mode === "dark";
  const { height } = useWindowSizeContext();

  useEffect(() => {
    if (submitted || error) {
      const timer = setTimeout(() => {
        setSubmitted(false);
        setError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitted, error]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);

    try {
      const response = await fetch("https://formspree.io/f/mgvwedav", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setSubmitted(true);
        setName("");
        setEmail("");
        setMessage("");
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };

  return (
    <Box
      style={{
        padding: "20px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: height < 800 ? "50px" : "0px",
      }}
    >
      <Typography
        variant="h3"
        style={{ maxWidth: "800px", textAlign: "center", marginBottom: "15px" }}
      >
        Feel free to reach out to me!
      </Typography>
      <form
        onSubmit={handleSubmit}
        style={{ maxWidth: "800px", width: "100%" }}
      >
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Message"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "20px" }}
        >
          Send Message
        </Button>
      </form>
      <Snackbar
        open={submitted}
        autoHideDuration={3000}
        onClose={() => setSubmitted(false)}
      >
        <Alert severity="success" style={{ position: "fixed", top: "20px" }}>
          Message sent successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={3000}
        onClose={() => setError(false)}
      >
        <Alert severity="error" style={{ position: "fixed", top: "20px" }}>
          There was an error sending your message. Please try again later.
        </Alert>
      </Snackbar>
      <div style={{ marginTop: "50px" }}>
        <a
          href="https://www.linkedin.com/in/mevans0911"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton>
            <LinkedInIcon style={{ fontSize: "3em", color: "#0077B5" }} />
          </IconButton>
        </a>

        <a
          href="https://github.com/mattevans0911"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton>
            <GitHubIcon
              style={{
                fontSize: "3em",
                color: isDarkMode ? "#8892af" : "#24292e",
              }}
            />
          </IconButton>
        </a>
      </div>
    </Box>
  );
}

export default Contact;
